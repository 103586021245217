<!--
 * @Author: lihuaruiz lihuarui@huawei.com
 * @Date: 2024-06-03 13:27:29
 * @LastEditors: lihuaruiz lihuarui@huawei.com
 * @LastEditTime: 2024-06-27 14:36:32
 * @FilePath: \vue2Admin\src\views\home\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="page-content border-bottom">
      <!-- 顶部搜索框 -->
      <div>
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <!-- <el-form-item label="日期">
            <el-date-picker
              v-model="searchInfo.time"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              class="form-item-width"
              clearable
            >
            </el-date-picker>
          </el-form-item> -->
          <el-form-item label="城市">
            <el-input
              v-model="searchInfo.city"
              placeholder="请输入城市"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="searchInfo.phone"
              placeholder="请输入手机号"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="渠道">
            <el-input
              v-model="searchInfo.supplier"
              placeholder="请输入渠道"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="车型" style="margin-top: 15px">
            <el-input
              v-model="searchInfo.model"
              placeholder="请输入车型"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item class="right-btns" style="margin-top: 15px">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="page-content margin-top">
      <!-- 表格组件 -->
      <el-table
        :data="tableData"
        default-sort
        stripe
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        :cell-style="cellStyle"
      >
        <el-table-column label="序号" width="70" align="left">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="tab in tableTitle"
          :key="tab.code"
          :label="tab.name"
          show-overflow-tooltip
          :width="tab.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span @click="getItemDetail(scope.row)" class="update-btn"
              >查看详情</span
            ></template
          >
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <!-- <div>
        <Pagination
          :total="dataTotal"
          :page="limitPage.page"
          :limit="limitPage.limit"
          @pagination="onChangePages"
        />
      </div> -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400, 500]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataTotal"
        >
        </el-pagination>
      </div>
      <!-- <div style="margin-top: 10px" class="limit_page">
        当前页码：第{{ itemIndex }}页
        <span
          v-for="item in this.dataTotal"
          :key="item.index"
          style="margin-left: 10px; font-size: 15px"
          @click="getItemIndex(item)"
        >
          {{ item }}
        </span>
        前往<el-input
          v-model="currentPage"
          @blur="getItemIndex(currentPage)"
          style="display: inline; width: 20px !important; margin-left: 10px"
        ></el-input>
      </div> -->
    </div>
  </div>
</template>

<script>
// import Pagination from "@/components/Pagination";
import { getInvitationList, SearchInvitationList } from "../../api/common";
import { removeEmptyValues } from "../../utils/removeEmptyValues";
import router from "vue-router";

export default {
  components: {
    // Pagination,
    // DescriptionList,
  },
  data() {
    return {
      // 查询数据
      searchInfo: {
        city: "",
        phone: "",
        model: "",
        supplier: "",
        id: "",
      },
      limitPage: {
        limit: 100,
        page: 1,
      },
      currentPage: 1,
      dialogVisible: false,
      // 表格数据
      tableData: [],
      itemDetail: {},
      isClose: "",
      // 表格标题数组
      tableTitle: [
        { name: "ID号", code: "id", width: "" },
        { name: "所在城市", code: "city", width: "" },
        { name: "车型", code: "model", width: "" },
        { name: "渠道", code: "supplierName", width: "" },
        { name: "上牌时间", code: "registrationTime", width: "" },
        { name: "手机号码", code: "phone", width: "" },
        { name: "线索下发时间", code: "evaluateTime", width: "" },
        { name: "上次联系时间", code: "lastFollowUpTime", width: "" },
        // { name: "预约检测", code: "detection", width: "" },
        { name: "联系状态", code: "contactState", width: "" },
      ],
      // 数据总数
      dataTotal: 0,
      currentPage4: 1,
      itemIndex: 1,
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    // 查询
    this.onGetDataList();
  },
  methods: {
    // 查询
    onSearch() {
      // this.searchInfo.page = 1;
      const result = removeEmptyValues(this.searchInfo);
      SearchInvitationList(result).then((res) => {
        this.tableData = res.data.queryResult.list;
      });
    },
    // 重置
    onReset() {
      this.onGetDataList();
    },
    // 核心查询数据方法
    onGetDataList() {
      getInvitationList(this.limitPage).then((res) => {
        this.tableData = res.data.queryResult.list;
        this.dataTotal = res.data.queryResult.total;
        this.tableData.forEach((item) => {
          item.phone =
            item.phone.slice(0, 3) + "****" + item.phone.slice(7, 11);
          item.registrationTime = item.registrationTime.slice(0, 7);
          item.supplierName =
            item.supplier == "sinopec"
              ? "雷峰塔"
              : item.supplier == "零跑"
              ? "灵隐寺"
              : item.supplier;
          item.lastFollowUpTime = item.lastFollowUpTime
            ? item.lastFollowUpTime
            : "—————";
          item.contactState = item.contactState ? item.contactState : "未联系";
          item.detection = item.detection == 1 ? "预约检测" : "估价";
          // this.cellStyle();
        });
      });
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // 状态列字体颜色
      if (row.detection === "预约检测" && columnIndex === 1) {
        return "color: #ff0000";
      } else if (row.detection === "估价" && columnIndex === 1) {
        return "color: #000000";
      } else {
        return "color: #1a1a1b";
      }
    },
    // 取消查看详情
    examineRow() {
      this.dialogVisible = false;
    },
    // 查看数据详情
    getItemDetail(row) {
      localStorage.setItem("id", row.id);
      console.log("row.id" + row.id);
      this.$router.push({
        name: "HomeDetail",
        path: "/home/detail",
        params: { id: row.id },
      });
    },

    // 分页方法
    onChangePages(pageInfo) {
      // const { page, limit } = pageInfo;
      // this.limitPage.page = page;
      // this.limitPage.size = limit;
      // this.limitPage = pageInfo;
      // console.log(this.limitPage);
      // this.onGetDataList(this.limitPage);
    },

    //每页条数改变时触发 选择一页显示多少行
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    //   this.limitPage.limit = val;
    //   this.onGetDataList(this.limitPage);
    // },
    // //当前页改变时触发 跳转其他页
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    //   this.limitPage.page = val;
    //   this.onGetDataList(this.limitPage);
    // },

    // //当前页码
    // getItemIndex(item) {
    //   this.itemIndex = item;
    //   this.limitPage.page = item;
    //   this.onGetDataList(this.limitPage);
    //   console.log(item);
    // },

    handleSizeChange(val) {
      this.limitPage.limit = val;
      console.log(`每页 ${val} 条`);
      this.onGetDataList(this.limitPage);
    },
    handleCurrentChange(val) {
      this.limitPage.page = val;
      console.log(`当前页: ${val}`);
      this.onGetDataList(this.limitPage);
    },
  },
};
</script>

<style lang="less" scoped>
.form-item-width {
  width: 250px;
}
.right-btns {
  position: absolute;
  right: 20px !important;
}
.el-form-item {
  margin-bottom: 4px !important;
}
.border-bottom {
  border-bottom: 1px solid #e2e2e4;
}
.margin-top {
  margin-top: -15px;
}
.update-btn {
  cursor: pointer;
  color: #409eff;
}
.limit_page {
  /deep/ .el-input__inner {
    width: 50px !important;
  }
}
</style>
